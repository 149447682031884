import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`MaxMind maintains a list of Do Not Sell My Personal Information requests. This
API provides a simple way to retrieve
`}
      <a {...{
        "href": "https://www.maxmind.com/en/accounts/current/do-not-sell-requests",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`privacy exclusion requests`}</a>
      {`
in an automated fashion.`}</p>
    <div {...{
      "id": "toc-request"
    }}><h2 {...{
        "id": "request",
        "parentName": "div"
      }}>{`Request`}</h2>
      <div {...{
        "id": "toc-request-uri",
        "parentName": "div"
      }}><h3 {...{
          "id": "request-uri",
          "parentName": "div"
        }}>{`Request URI`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`API calls should be made with HTTP GET request to:
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`https://api.maxmind.com/privacy/exclusions`}</inlineCode></p></div>
      <div {...{
        "id": "toc-authorizationsecurity",
        "parentName": "div"
      }}><h3 {...{
          "id": "authorizationsecurity",
          "parentName": "div"
        }}>{`Authorization/Security`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We use
`}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/Basic_access_authentication",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`basic HTTP authentication`}</a>
          {`.
The HTTP `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Authorization`}</inlineCode>
          {` header is required for authorization. The username is
your
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/license-key",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`MaxMind account ID`}</a>
          {`.
The password is your
`}
          <a {...{
            "href": "https://www.maxmind.com/en/accounts/current/license-key",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`MaxMind license key`}</a>
          {`.
The authorization realm is `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`privacy-exclusion`}</inlineCode>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`The API is only available via HTTPS. The credentials are never transmitted
unencrypted. If you attempt to access this service via HTTP, you will receive a
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`403 Forbidden`}</inlineCode>
          {` HTTP response.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`We require TLS 1.2 or greater for all requests to our servers to keep your data
secure.`}</p></div>
      <div {...{
        "id": "toc-request-parameters",
        "parentName": "div"
      }}><h3 {...{
          "id": "request-parameters",
          "parentName": "div"
        }}>{`Request Parameters`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The query string may include the following parameter:`}</p>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Key`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`Value Type`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`Description`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`updates_after`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}>{`RFC 3339 timestamp`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`If set, only updates made after this time will be returned. The value should be a valid `}
                <a {...{
                  "href": "https://tools.ietf.org/html/rfc3339",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "td"
                }}>{`RFC 3339 timestamp`}</a>
                {`, e.g., `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`2020-04-12T23:20:50.52Z`}</inlineCode>
                {`.`}</td>

            </tr>

          </tbody>

        </table>
        <div {...{
          "id": "toc-example-request-url",
          "parentName": "div"
        }}><h4 {...{
            "id": "example-request-url",
            "parentName": "div"
          }}>{`Example Request URL`}</h4>
          <p {...{
            "parentName": "div"
          }}><inlineCode {...{
              "parentName": "p"
            }}>{`https://api.maxmind.com/privacy/exclusions?updates_after=2020-04-12T23:20:50.52Z`}</inlineCode></p></div></div></div>
    <div {...{
      "id": "toc-response"
    }}><h2 {...{
        "id": "response",
        "parentName": "div"
      }}>{`Response`}</h2>
      <div {...{
        "id": "toc-response-headers",
        "parentName": "div"
      }}><h3 {...{
          "id": "response-headers",
          "parentName": "div"
        }}>{`Response Headers`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Content-Type`}</inlineCode>
          {` header will vary based on whether the request was successful
or not, and is detailed further in each of the two Response Body sections below.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`The response will always include a `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Content-Length`}</inlineCode>
          {` header.`}</p></div>
      <div {...{
        "id": "toc-response-body-for-successful-requests",
        "parentName": "div"
      }}><h3 {...{
          "id": "response-body-for-successful-requests",
          "parentName": "div"
        }}>{`Response Body (for successful requests)`}</h3>
        <div {...{
          "id": "toc-example",
          "parentName": "div"
        }}><h4 {...{
            "id": "example",
            "parentName": "div"
          }}>{`Example`}</h4>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-json",
              "parentName": "pre"
            }}>{`{
    "exclusions": [
        {
            "exclusion_type": "ccpa_do_not_sell",
            "data_type": "network",
            "value": "10.0.26.166/32",
            "last_updated":"2020-01-08T18:58:38Z"
        },
        ...
    ]
}
`}</code></pre></div>
        <div {...{
          "id": "toc-description",
          "parentName": "div"
        }}><h4 {...{
            "id": "description",
            "parentName": "div"
          }}>{`Description`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`The `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`Content-Type`}</inlineCode>
            {` header for a successful response will be
`}
            <inlineCode {...{
              "parentName": "p"
            }}>{`application/vnd.maxmind.com-privacy-exclusions+json; charset=UTF-8; version=1.0`}</inlineCode></p>
          <p {...{
            "parentName": "div"
          }}>{`Data will be returned as a JSON document in UTF-8 encoding. The document will be
a JSON object including the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`exclusions`}</inlineCode>
            {` key. Additional keys may be added in
the future.`}</p>
          <p {...{
            "parentName": "div"
          }}>{`The value for the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`exclusions`}</inlineCode>
            {` key is an array of objects, each representing one
exclusion request.`}</p>
          <p {...{
            "parentName": "div"
          }}>{`Each exclusion object in the `}
            <inlineCode {...{
              "parentName": "p"
            }}>{`exclusions`}</inlineCode>
            {` array includes the following keys:`}</p>
          <table {...{
            "parentName": "div"
          }}>

            <thead {...{
              "parentName": "table"
            }}>

              <tr {...{
                "parentName": "thead"
              }}>

                <th {...{
                  "parentName": "tr"
                }}>{`Key`}</th>


                <th {...{
                  "parentName": "tr"
                }}>{`Value Type`}</th>


                <th {...{
                  "parentName": "tr"
                }}>{`Description`}</th>

              </tr>

            </thead>


            <tbody {...{
              "parentName": "table"
            }}>

              <tr {...{
                "parentName": "tbody"
              }}>

                <td {...{
                  "parentName": "tr"
                }}><inlineCode {...{
                    "parentName": "td"
                  }}>{`exclusion_type`}</inlineCode></td>


                <td {...{
                  "parentName": "tr"
                }}>{`enum`}</td>


                <td {...{
                  "parentName": "tr"
                }}>{`The governing law or rule that the exclusion was made under. Currently, the only valid type is `}
                  <inlineCode {...{
                    "parentName": "td"
                  }}>{`ccpa_do_not_sell`}</inlineCode>
                  {` for the California Consumer Privacy Act’s “Do Not Sell My Personal Information” provision.`}</td>

              </tr>


              <tr {...{
                "parentName": "tbody"
              }}>

                <td {...{
                  "parentName": "tr"
                }}><inlineCode {...{
                    "parentName": "td"
                  }}>{`data_type`}</inlineCode></td>


                <td {...{
                  "parentName": "tr"
                }}>{`enum`}</td>


                <td {...{
                  "parentName": "tr"
                }}>{`The data type of the value being excluded. Currently, the only valid data type is `}
                  <inlineCode {...{
                    "parentName": "td"
                  }}>{`network`}</inlineCode>
                  {`, which is an IP network in `}
                  <a {...{
                    "href": "https://en.wikipedia.org/wiki/Classless_Inter-Domain_Routing#CIDR_notation",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "td"
                  }}>{`CIDR notation`}</a>
                  {`; all IP addresses in the specified network should be excluded. You should always check this before using the associated `}
                  <inlineCode {...{
                    "parentName": "td"
                  }}>{`value`}</inlineCode>
                  {`. In the future, additional types may be added.`}</td>

              </tr>


              <tr {...{
                "parentName": "tbody"
              }}>

                <td {...{
                  "parentName": "tr"
                }}><inlineCode {...{
                    "parentName": "td"
                  }}>{`value`}</inlineCode></td>


                <td {...{
                  "parentName": "tr"
                }}>{`string`}</td>


                <td {...{
                  "parentName": "tr"
                }}>{`The value being excluded.`}</td>

              </tr>


              <tr {...{
                "parentName": "tbody"
              }}>

                <td {...{
                  "parentName": "tr"
                }}><inlineCode {...{
                    "parentName": "td"
                  }}>{`last_updated`}</inlineCode></td>


                <td {...{
                  "parentName": "tr"
                }}>{`RFC 3339 timestamp`}</td>


                <td {...{
                  "parentName": "tr"
                }}>{`The time of the last update to the exclusion as an `}
                  <a {...{
                    "href": "https://tools.ietf.org/html/rfc3339",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "td"
                  }}>{`RFC 3339 timestamp`}</a>
                  {`.`}</td>

              </tr>

            </tbody>

          </table>
          <p {...{
            "parentName": "div"
          }}>{`Please note that additional keys may be added in the future.`}</p></div></div>
      <div {...{
        "id": "toc-response-body-for-unsuccessful-requests",
        "parentName": "div"
      }}><h3 {...{
          "id": "response-body-for-unsuccessful-requests",
          "parentName": "div"
        }}>{`Response Body (for unsuccessful requests)`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`In the event an error occurs (the response indicates a 4xx or 5xx HTTP status),
the response may include a JSON document in the body. An error in content
negotiation will not include a body nor will many 5xx errors. Before attempting
to decode the body as JSON, you should verify that the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`Content-Type`}</inlineCode>
          {` of the
error response is
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`application/vnd.maxmind.com-error+json; charset=UTF-8; version=1.0`}</inlineCode>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`If the JSON document `}
          <em {...{
            "parentName": "p"
          }}>{`is`}</em>
          {` included in the response body, it will be an object
with the keys `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`code`}</inlineCode>
          {` and `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`error`}</inlineCode>
          {`. The `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`code`}</inlineCode>
          {` field is a static error code for
machine use. The value of any given `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`code`}</inlineCode>
          {` will never change, but `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`code`}</inlineCode>
          {`s can be
added or removed. The error field is a human-readable description of the error
and may change at any time.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`In addition to the errors documented below, client code should also be prepared
to handle any valid HTTP 4xx or 5xx status code.`}</p>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Code Error`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`HTTP Status`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`Error Mode`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`TIMESTAMP_INVALID`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`400 Bad Request`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`The `}
                <inlineCode {...{
                  "parentName": "td"
                }}>{`updates_after`}</inlineCode>
                {` field must be in `}
                <a {...{
                  "href": "https://tools.ietf.org/html/rfc3339",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "td"
                }}>{`RFC 3339 format`}</a>
                {`.`}</td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`AUTHORIZATION_INVALID`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`401 Unauthorized`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`Your `}
                <a {...{
                  "href": "https://www.maxmind.com/en/accounts/current/license-key",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "td"
                }}>{`account ID or license key`}</a>
                {` could not be authenticated.`}</td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`ACCOUNT_ID_REQUIRED`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`401 Unauthorized`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`An `}
                <a {...{
                  "href": "https://www.maxmind.com/en/accounts/current/license-key",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "td"
                }}>{`account ID and license key`}</a>
                {` are required to use this service.`}</td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`LICENSE_KEY_REQUIRED`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`401 Unauthorized`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`An `}
                <a {...{
                  "href": "https://www.maxmind.com/en/accounts/current/license-key",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "td"
                }}>{`account ID and license key`}</a>
                {` are required to use this service.`}</td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`PERMISSION_REQUIRED`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`403 Forbidden`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`You do not have permission to use the service. Please contact `}
                <a {...{
                  "href": "mailto:support@maxmind.com",
                  "parentName": "td"
                }}>{`support@maxmind.com`}</a>
                {` for more information.`}</td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}><em {...{
                  "parentName": "td"
                }}>{`(none)`}</em></td>


              <td {...{
                "parentName": "tr"
              }}>{`503 Service Not Available`}</td>


              <td {...{
                "parentName": "tr"
              }}>{`There is a problem with the web service server. You can `}
                <a {...{
                  "href": "https://status.maxmind.com",
                  "target": "_blank",
                  "rel": "nofollow noopener noreferrer",
                  "parentName": "td"
                }}>{`check the status of our services`}</a>
                {`, or try this request again later.`}</td>

            </tr>

          </tbody>

        </table>
        <div {...{
          "id": "toc-example-response-for-an-unsuccessful-request",
          "parentName": "div"
        }}><h4 {...{
            "id": "example-response-for-an-unsuccessful-request",
            "parentName": "div"
          }}>{`Example Response (for an unsuccessful request)`}</h4>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-json",
              "parentName": "pre"
            }}>{`{
    "code": "ACCOUNT_ID_REQUIRED",
    "error": "You have not supplied a MaxMind account ID in the Authorization header"
}
`}</code></pre></div></div></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      